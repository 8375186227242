import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom";
import { NotificationContainer } from 'react-notifications';
import ReactGA from 'react-ga';
import "./assets/styles/style-help.css";
import "./assets/styles/style.css";
import "./assets/styles/animation.css"
import "./assets/fonts/stylesheet.css";
import "./assets/styles/index.css";


import Home from "./pages/website/Home";
import About from "./pages/website/About";
import FAQ from "./pages/website/FAQ"
import Sponsorship from "./pages/website/Sponsorship";
import Conferences from "./pages/website/Conferences";
import SingleConference from "./pages/website/SingleConference";
import PreTeaser from "./pages/website/PreTeaser";
import Contact from "./pages/website/Contact";
import Terms from "./pages/website/Terms";
import Privacy from "./pages/website/Privacy";
import Gallery from "./pages/website/Gallery";
import Events from "./pages/website/Events";
import Online from "./pages/website/Online";
import Offline from "./pages/website/Offline";
import NotFound from "./pages/website/NotFound";
import SingleGallery from "./pages/website/SingleGallery";
import SecondSponsorship from "./pages/website/SecondSponsorship";
import ThirdSponsorship from "./pages/website/ThirdSponsorship";
import Unsubscribe from "./pages/website/Unsubscribe";
import EvaluationForm from "./pages/website/evaluationform/EvaluationForm"
import Stripe from "./pages/website/Srtipe";
import JivositeWidget from "./components/JivositeWidget";
import Fortuna from "./components/Fortuna";
import RedirectToBTEL from "./components/RedirectToBTEL";
import RedirectToGenotoxicity from "./components/RedirectToGenotoxicity";
import PostMaterial from "./pages/website/PostMaterial";
import PastEvent from "./pages/website/PastEvent";
import {AuthProvider} from "./components/auth/AuthProvider";
import Cancelation from "./pages/website/Cancelation";
import Data from "./pages/website/Data";
import DownloadSecuritiesJSON from "./pages/website/DownloadSecuritiesJSON";


function App() {
    useEffect(() => {
        console.log("Initializing Google Analytics");
        ReactGA.initialize('UA-107059903-3');
        console.log("Google Analytics Initialized");

        // Обновлённый код Яндекс.Метрики с включением webvisor
        (function(m,e,t,r,i,k,a){
            m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
            m[i].l=1*new Date();
            k=e.createElement(t),
                a=e.getElementsByTagName(t)[0],
                k.async=1;
            k.src=r;
            a.parentNode.insertBefore(k,a)
        })(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

        console.log("Yandex.Metrika script added");

        ym(98633498, "init", {
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true // Включаем webvisor для записи сессий
        });

        console.log("Yandex.Metrika initialized with webvisor enabled");
    }, []);



    return (
        <Router>
            <AppContent />
        </Router>
    );
}

function usePageViews() {
    let location = useLocation();
    useEffect(() => {
        console.log("Page view registered: ", location.pathname + location.search);
        ReactGA.pageview(location.pathname + location.search);

        // Проверяем существование ym и логируем результат
        if (typeof ym !== 'undefined') {
            ym(98633498, 'hit', location.pathname + location.search);
            console.log(`Yandex.Metrika pageview registered: ${location.pathname + location.search}`);
        } else {
            console.log("Yandex.Metrika is not defined, script might not have loaded correctly.");
        }
    }, [location]);
}





function AppContent() {
    usePageViews();
    return (
        <>
        <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/about" component={About} />
            <Route path="/faq" component={FAQ} />
            <Route path="/sponsorship" component={Sponsorship} />
            <Route path="/secondsponsorship" component={SecondSponsorship} />
            <Route path="/thirdsponsorship" component={ThirdSponsorship} />
            <Route path="/conferences" component={Conferences} />
            <Route path="/single-conference/4th-annual-extractables-leachables-conference" component={RedirectToBTEL} />
            <Route path="/single-conference/5th-annual-biocompatibility-and-toxicology-for-medical-devices-summit" component={RedirectToBTEL} />
            <Route path="/single-conference/2nd-annual-genotoxicity-and-nitrosamine-summit" component={RedirectToGenotoxicity} />
            <Route path="/single-conference/:id" component={SingleConference} />
            <Route path="/specific-conference/:id" render={(props) => <SingleConference id={props.match.params.id} />} />
            <Route path="/preteaser/:id" component={PreTeaser} />
            <Route path="/pastevent/:id" component={PastEvent} />
            <Route path="/contacts" component={Contact} />
            <Route path="/terms" component={Terms} />
            <Route path="/cancelation" component={Cancelation} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/gallery" component={Gallery} />
            <Route path="/single-gallery/:id" component={SingleGallery} />
            <Route path="/data" component={Data} />
            <Route path="/data.json" component={DownloadSecuritiesJSON} />
            <Route path="/events" component={Events} />
            <Route path="/online" component={Online} />
            <Route path="/offline" component={Offline} />
            <Route path="/stripe/:id" component={Stripe} />
            <Route path="/unsubscribe/:id" component={Unsubscribe} />
            <Route path="/evaluationform/:id" component={EvaluationForm} />
            <AuthProvider>
                <Route path="/postmaterial/:id" component={PostMaterial} />
            </AuthProvider>
            <Route path="*" component={NotFound} />
        </Switch>
        <NotificationContainer/>
        <JivositeWidget />
            <Fortuna />

        </>
);
}

export default App;
