import React, { useContext, useEffect, useState } from "react";
import Footer from '../../components/website/Footer';
import HeaderConferences from '../../components/website/HeaderConferences';
import "magnific-popup";
import api from "../../api/endpoints/events.js";
import "rodal/lib/rodal.css";
import Breadcrumbs from "../../components/Breadcrumbs";
import {useParams} from "react-router-dom";
import AuthContext from "../../components/auth/context/AuthContext";
import LoginPage from "../../components/auth/login/LoginPage";

function PostMaterial() {
    const {auth, updateSpeakers} = useContext(AuthContext);
    const [setIsValidId] = useState(true);


    const eventInhalation = ["7TH ANNUAL INHALATION & RESPIRATORY DRUG DELIVERY CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION", "ADVANCED PLASTICS RECYCLING CONFERENCE AND CHRISTMAS INDUSTRY CELEBRATION"]

    const speakerNamesToExclude = ["Daniel Tyrkas", "Jordan Khedr", "Mohamed Essam Ahmed", "Adam Smith",
                                            "Dr. Christian Mani", "Mònica Cardona", "Marielle Legair", "Kirti Moholkar",
                                            "Karleen Parchment", "Christian Ntwari", "Kate O'Brien", "Ivan Holub", "Joeri Dieltjens"];

    const conferenceLinks = {
        "5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE": "https://bcf-events.com/single-gallery/f883f197-11eb-4ad2-8e0d-39a2417555b2",
        "5TH ANNUAL BTEL SUMMIT & EXHIBITION": "https://drive.google.com/drive/folders/1mnqpNYLn313M0x2rOwh2oTw05gMJSFT8"
    };
    const futureEvents = {
        "5TH ANNUAL BTEL SUMMIT & EXHIBITION": "https://bcf-events.com/single-conference/6th-annual-btel-summit-exhibition"
    }

    const uncutVideoConference = ["THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE"]

    const myEventListButtons = ["5TH ANNUAL ADVANCED PLASTICS RECYCLING HYBRID CONFERENCE", "5TH ANNUAL BTEL SUMMIT & EXHIBITION"];

    const { id } = useParams();
    const [conference, setConference] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                window.scrollTo(0, 0);

                const { data } = await api.get(`/Event/get-event-by-slug/${id}`);
                if (data.endDate == null) {
                    setIsValidId(false); // Используется setIsValidId
                    return;
                }
                setConference(data);
                console.log('Conference id: ', data);
                updateSpeakers(data.speakers); // Используется updateSpeakers
            } catch (error) {
                console.error('Error fetching event data:', error);
            }
        };

        fetchData();
    }, [id, updateSpeakers, setIsValidId]); // Добавлено setIsValidId


    if (!auth) {
        return (
            <LoginPage conference={conference} />
        );
    }

    const firstDayVideoUrl = "https://youtu.be/On9EOJJetog";
    const secondDayVideoUrl = "https://youtu.be/vpbWO6xA_V8";
    return (
        <div>
            <HeaderConferences />
            <main>
                <section>
                    <div className="background-block unsubscribe-block">
                        <div className="container">
                            <div className="breadcrumbs-about">
                                <ul>
                                    <a href="/">
                                        <li className="andThisStyle title-breadcrumbs unsubscribe-breadcrumbs">MAIN</li>
                                    </a>
                                    <a href="/unsubscribe">
                                        <li className="thisStyle description-breadcrumbs unsubscribe-breadcrumbs">
                                            POSTMATERIALS
                                        </li>
                                    </a>
                                </ul>
                                <Breadcrumbs />
                            </div>
                            <div className="background-single"></div>
                            <img className="unsubscribe-image" src={require('../../assets/images/unsubscribe.jpeg')} alt="Unsubscribe" />
                            <div className="centered-block">
                                <h2>{conference.eventName}</h2>
                                <h3>
                                    We also invite you to fill out the evaluation form at{' '}
                                    <a
                                        href={conference.eventUrl}
                                        style={{ color: 'skyblue' }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        this link
                                    </a>
                                </h3>
                                <div className="centered-block-postmaterial">
                                    {eventInhalation.includes(conference.eventName) && (
                                    <a className="popup-with-zoom-anim2" target="_blank" rel="noreferrer" href={conference.hotelBookingUrl}>
                                        <button
                                            className="delegate-list-button"
                                        >
                                            Delegate List
                                        </button>
                                    </a> )}
                                    {uncutVideoConference.includes(conference.eventName) && (
                                        <div className="video-buttons">
                                            <a className="popup-with-zoom-anim2" target="_blank" rel="noopener noreferrer" href={firstDayVideoUrl}>
                                                <button
                                                    className="first-day-video-button"
                                                >
                                                    First Day Video
                                                </button>
                                            </a>
                                            <a className="popup-with-zoom-anim2" target="_blank" rel="noopener noreferrer" href={secondDayVideoUrl}>
                                                <button
                                                    className="second-day-video-button"
                                                >
                                                    Second Day Video
                                                </button>
                                            </a>
                                        </div>
                                    )}
                                    {/* Остальные кнопки */}
                                    {myEventListButtons.includes(conference.eventName) && (
                                        <a className="popup-with-zoom-anim2" target="_blank" rel="noopener noreferrer" href={conferenceLinks[conference.eventName]}>
                                            <button
                                                style={{
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                    backgroundColor: '#1089f1',
                                                    color: '#fff',
                                                    border: 'none',
                                                    padding: '15px 30px',
                                                    textAlign: 'center',
                                                    textDecoration: 'none',
                                                    display: 'inline-block',
                                                    fontSize: '24px',
                                                    cursor: 'pointer',
                                                    borderRadius: '5px',
                                                }}
                                            >
                                                Conference Photos
                                            </button>
                                        </a>
                                    )}
                                </div>
                                {myEventListButtons.includes(conference.eventName) && (
                                    <a className="popup-with-zoom-anim2" target="_blank" rel="noopener noreferrer" href={futureEvents[conference.eventName]}>
                                        <button style={{
                                            marginTop: '45px',
                                        }} className="sponsors-button">
                                            BTEL 2025
                                        </button>
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
                {conference.speakers && conference.speakers.length > 0 && (
                    <section className="section-speakers">
                        <div className="container">
                            <h2 className="title-about title-about-single">Speakers</h2>
                            <div className="line-container">
                                <span className="line-about"></span>
                            </div>
                            <ul className="grid-container">
                                {conference.speakers &&
                                    conference.speakers
                                        .filter((speaker) => {
                                            // Фильтрация по идентификаторам или именам спикеров
                                            return (
                                                !speakerNamesToExclude.includes(speaker.fullName)
                                            );
                                        })
                                        .map((speaker) => (
                                        <li className="grid-item" key={speaker.id}>
                                            <div>
                                                <a className="popup-with-zoom-anim speaker-style"
                                                   href={`#small-dialog-${speaker.id}`}
                                                >

                                                        <img
                                                            src={`${speaker.profileImage}`}
                                                            alt=""
                                                            className="flip-img mobile-image"
                                                        />
                                                </a>
                                            </div>
                                            <p className="name">{speaker.fullName}</p>
                                            <p className="company">{speaker.company}</p>
                                            <div className="buttons">
                                                <button className="post_button_presentation">
                                                    <a href={speaker.artDeco}
                                                       target="_blank"
                                                       rel="noopener noreferrer"
                                                       style={{ color: 'white', textDecoration: 'none' }} // Замените 'cream' на конкретный цвет в формате, который вы используете
                                                    >
                                                        Presentation
                                                    </a>
                                                </button>
                                                {speaker.videoLink != null && (
                                            <button className="post_button_presentation">
                                                <a href={speaker.videoLink}
                                                   target="_blank"
                                                   rel="noopener noreferrer"
                                                   style={{ color: 'white', textDecoration: 'none' }} // Замените 'cream' на конкретный цвет в формате, который вы используете
                                                >
                                                    Presentation Video
                                                </a>
                                            </button>)}
                                            </div>
                                                {/* новая вкладка */}
                                            <div> 
                                            {/*<a href={speaker.speakersUrlLogo} */}
                                            {/* target="_blank"*/}
                                            {/* rel="noopener noreferrer"  */}
                                            {/*style={{ color: '#00005A', fontWeight: 'bold', textDecoration: 'none' }}>*/}
                                            {/*<img*/}
                                            {/*    className="logo-company"*/}
                                            {/*    src={speaker.logo}*/}
                                            {/*    alt=""*/}
                                            {/*    style={{ display: 'flex' }}*/}
                                            {/*/>*/}
                                            {/*</a>*/}
                                            </div>
                                            <p className="position">
                                                <a href={speaker.speakersUrlLogo}
                                                target="_blank"
                                                rel="noopener noreferrer" 
                                                style={{ color: '#00005A', fontWeight: 'bold', textDecoration: 'none' }}>
                                                    {speaker.position}
                                                </a>
                                            </p>

                                            <div className="scroll-container-wrapper">
                                                <div id={`small-dialog-${speaker.id}`} className="zoom-anim-dialog mfp-hide my-custom-popup-style scroll-container">
                                                    <div className="popup-flex">
                                                        <img className="popup-img" src={speaker.profileImage} alt="" />
                                                        <div className="popup-direction">
                                                            <p className="name mobile-name">{speaker.fullName}</p>
                                                            <p className="position mobile-position">
                                                                {speaker.position}
                                                            </p>
                                                            <p className="company mobile-company">
                                                                {speaker.company}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <p className="popup-desc" dangerouslySetInnerHTML={{ __html: speaker?.description }}></p>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                            </ul>

                        </div>
                    </section>
                )}

            </main>
            <Footer />
        </div>
    );
}

export default PostMaterial;
