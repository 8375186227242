// src/pages/website/Data.jsx
import React, { useState, useEffect, useCallback } from 'react';
import { fetchData } from '../../api';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';

// Стили с использованием styled-components

const Container = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
`;

const AuthContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
`;

const AuthForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 300px;
    gap: 10px;
`;

const AuthInput = styled.input`
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
`;

const AuthButton = styled.button`
    padding: 10px;
    font-size: 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: #45a049;
    }
`;

const FilterForm = styled.form`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 5px;
`;

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
`;

const FormLabel = styled.label`
    margin-bottom: 5px;
    font-weight: bold;
`;

const FormInput = styled.input`
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
`;

const FormButtons = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media (max-width: 768px) {
        flex-direction: row;
        gap: 10px;
    }
`;

const FilterButton = styled.button`
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    margin-bottom: 10px;

    &:hover {
        opacity: 0.8;
    }

    @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 0;
    }
`;
const DownloadButton = styled.button`
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #28a745;
    color: #fff;
    margin-bottom: 10px;

    &:hover {
        opacity: 0.8;
    }

    @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 0;
    }
`;

const ResetButton = styled.button`
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #6c757d;
    color: #fff;

    &:hover {
        opacity: 0.8;
    }

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const SuccessMessage = styled.div`
    color: green;
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
`;

const ErrorMessage = styled.div`
    color: red;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
`;

const Loading = styled.div`
    font-size: 18px;
    color: #007bff;
    text-align: center;
    margin-top: 20px;
`;

const TableResponsive = styled.div`
    width: 100%;
    overflow-x: auto; /* Добавляет горизонтальную прокрутку при необходимости */
    margin-bottom: 20px;
`;

const DataTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    min-width: 800px; /* Минимальная ширина таблицы для корректного отображения */

    @media (max-width: 768px) {
        min-width: 600px; /* Уменьшаем минимальную ширину для мобильных */
    }
`;

const TableHeader = styled.th`
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
    white-space: nowrap; /* Предотвращает перенос текста */
    background-color: #f2f2f2;
`;

const TableData = styled.td`
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
    white-space: nowrap; /* Предотвращает перенос текста */
`;

const TableRow = styled.tr`
    &:nth-child(odd) {
        background-color: #f9f9f9;
    }

    &:hover {
        background-color: #f1f1f1;
    }
`;

const Pagination = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 5px;
`;

const PageButton = styled.button`
    padding: 8px 12px;
    border: 1px solid #007bff;
    background-color: #fff;
    color: #007bff;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;

    &.active,
    &:hover {
        background-color: #007bff;
        color: #fff;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
`;

const NoData = styled.p`
    font-size: 16px;
    color: #555;
    text-align: center;
    margin-top: 20px;
`;

const Data = () => {
    const location = useLocation();
    const history = useHistory();
    const [allData, setAllData] = useState([]); // Все данные
    const [filteredData, setFilteredData] = useState([]); // Отфильтрованные данные
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Состояния для фильтров
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [pid, setPid] = useState('');
    const [rid, setRid] = useState('');

    // Состояния для пагинации
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 10;

    // Сообщение об успешной фильтрации
    const [successMessage, setSuccessMessage] = useState('');

    // Аутентификация
    const [authenticated, setAuthenticated] = useState(false);
    const [inputPassword, setInputPassword] = useState('');
    const HARDCODED_PASSWORD = 'Bcf2023'; // Замените на ваш пароль

    /**
     * Функция для инициализации фильтров из URL
     * Оборачиваем в useCallback для стабилизации функции
     */
    const initializeFiltersFromURL = useCallback(() => {
        const params = new URLSearchParams(location.search);
        const date = params.get('startDate');
        const pidParam = params.get('pid');
        const ridParam = params.get('rid');

        if (date) {
            setStartDate(date);
            setEndDate(date); // Если дата одна, устанавливаем как start и end
        }
        if (pidParam) {
            setPid(pidParam);
        }
        if (ridParam) {
            setRid(ridParam);
        }
    }, [location.search]);

    /**
     * Хук для инициализации фильтров при монтировании компонента
     */
    useEffect(() => {
        initializeFiltersFromURL();
    }, [initializeFiltersFromURL]);

    /**
     * Хук для загрузки данных при аутентификации
     */
    useEffect(() => {
        if (!authenticated) return; // Не выполнять запрос, если не авторизован

        // Загружаем все данные при монтировании компонента
        fetchData()
            .then((data) => {
                setAllData(data);
                setFilteredData(data);
                setLoading(false);
            })
            .catch((err) => {
                console.error('Error fetching data:', err);
                setError(err.message);
                setLoading(false);
            });
    }, [authenticated]);

    /**
     * Хук для инициализации фильтров при изменении URL или данных
     */
    useEffect(() => {
        if (!authenticated) return; // Не инициализировать фильтры, если не авторизован
        // Инициализируем фильтры из URL при изменении URL
        initializeFiltersFromURL();
    }, [initializeFiltersFromURL, location.search, allData, authenticated]);

    /**
     * Хук для применения фильтров при их изменении
     */
    useEffect(() => {
        if (!authenticated) return; // Не применять фильтры, если не авторизован
        applyFilters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate, pid, rid, allData, authenticated]);

    /**
     * Функция для применения фильтров
     */
    const applyFilters = useCallback(() => {
        let filtered = allData;

        // Валидация: убедиться, что startDate <= endDate
        if (startDate && endDate) {
            const start = new Date(startDate);
            const end = new Date(endDate);
            if (start > end) {
                setError('Начальная дата не может быть позже конечной даты.');
                setFilteredData([]);
                setSuccessMessage('');
                return;
            }
        }

        setError(null); // Сбросить ошибку, если валидация прошла успешно

        // Фильтрация по начальной дате
        if (startDate) {
            const start = new Date(startDate);
            filtered = filtered.filter(item => {
                const itemDate = new Date(item['Date']);
                return itemDate >= start;
            });
        }

        // Фильтрация по конечной дате
        if (endDate) {
            const end = new Date(endDate);
            end.setHours(23, 59, 59, 999); // Устанавливаем время на конец дня
            filtered = filtered.filter(item => {
                const itemDate = new Date(item['Date']);
                return itemDate <= end;
            });
        }

        // Фильтрация по PID
        if (pid.trim() !== '') {
            filtered = filtered.filter(item => item['PID'].toString().trim() === pid.trim());
        }

        // Фильтрация по RID
        if (rid.trim() !== '') {
            filtered = filtered.filter(item => item['RID'].toString().trim() === rid.trim());
        }

        setFilteredData(filtered);
        setCurrentPage(1); // Сбросить пагинацию
    }, [allData, startDate, endDate, pid, rid]);

    /**
     * Обработчик отправки формы фильтрации
     */
    const handleFilterSubmit = (e) => {
        e.preventDefault();
        applyFilters();

        // Обновление URL с текущими фильтрами
        const params = new URLSearchParams();
        if (startDate) params.append('startDate', startDate);
        if (pid) params.append('pid', pid);
        if (rid) params.append('rid', rid);

        history.push({ search: params.toString() });
    };

    /**
     * Обработчик сброса фильтров
     */
    const handleResetFilters = () => {
        setStartDate('');
        setEndDate('');
        setPid('');
        setRid('');
        setFilteredData(allData);
        setCurrentPage(1);
        setError(null); // Сбросить ошибку
        setSuccessMessage('Фильтры сброшены.');

        // Сброс URL параметров
        history.push({ search: '' });
    };

    // Индексы для текущей страницы
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = filteredData.slice(indexOfFirstRecord, indexOfLastRecord);
    const totalPages = Math.ceil(filteredData.length / recordsPerPage);

    // Обработчик переключения страниц
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    /**
     * Функция для скачивания JSON
     */
    const downloadJSON = () => {
        const dataStr = JSON.stringify(filteredData, null, 2); // Форматирование JSON с отступами
        const blob = new Blob([dataStr], { type: "application/json" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');

        // Генерация имени файла с учетом фильтров
        let fileName = 'securities';
        const params = [];
        if (startDate) params.push(`date=${startDate}`);
        if (pid) params.push(`pid=${pid}`);
        if (rid) params.push(`rid=${rid}`);
        if (params.length > 0) {
            fileName += `_${params.join('_')}`;
        }
        fileName += `.json`;

        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    /**
     * Функция для конвертации данных в CSV и скачивания файла
     */
    const downloadCSV = () => {
        if (filteredData.length === 0) {
            setError("Нет данных для скачивания.");
            return;
        }

        const headers = Object.keys(filteredData[0]);
        const csvRows = [];

        // Добавить заголовки
        csvRows.push(headers.join(','));

        // Добавить строки данных
        filteredData.forEach(row => {
            const values = headers.map(header => {
                let escaped = ('' + row[header]).replace(/"/g, '""');
                if (escaped.search(/("|,|\n)/g) >= 0) {
                    escaped = `"${escaped}"`;
                }
                return escaped;
            });
            csvRows.push(values.join(','));
        });

        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');

        // Генерация имени файла с учетом фильтров
        let fileName = 'securities';
        const params = [];
        if (startDate) params.push(`date=${startDate}`);
        if (pid) params.push(`pid=${pid}`);
        if (rid) params.push(`rid=${rid}`);
        if (params.length > 0) {
            fileName += `_${params.join('_')}`;
        }
        fileName += `.csv`;

        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    /**
     * Обработчик отправки формы аутентификации
     */
    const handleAuthSubmit = (e) => {
        e.preventDefault();
        if (inputPassword === HARDCODED_PASSWORD) {
            setAuthenticated(true);
            setError(null);
        } else {
            setError('Неверный пароль.');
        }
    };

    if (!authenticated) {
        return (
            <AuthContainer>
                <h2>Введите пароль для доступа к данным</h2>
                <AuthForm onSubmit={handleAuthSubmit}>
                    <AuthInput
                        type="password"
                        value={inputPassword}
                        onChange={(e) => setInputPassword(e.target.value)}
                        placeholder="Пароль"
                        required
                    />
                    <AuthButton type="submit">Войти</AuthButton>
                </AuthForm>
                {error && <ErrorMessage>{error}</ErrorMessage>}
            </AuthContainer>
        );
    }

    return (
        <Container>
            <h1>Agenda Data</h1>

            {/* Форма фильтрации */}
            <FilterForm onSubmit={handleFilterSubmit}>
                <FormGroup>
                    <FormLabel htmlFor="startDate">От:</FormLabel>
                    <FormInput
                        type="date"
                        id="startDate"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <FormLabel htmlFor="endDate">До:</FormLabel>
                    <FormInput
                        type="date"
                        id="endDate"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <FormLabel htmlFor="pid">PID:</FormLabel>
                    <FormInput
                        type="text"
                        id="pid"
                        value={pid}
                        onChange={(e) => setPid(e.target.value)}
                        placeholder="Введите PID"
                    />
                </FormGroup>
                <FormGroup>
                    <FormLabel htmlFor="rid">RID:</FormLabel>
                    <FormInput
                        type="text"
                        id="rid"
                        value={rid}
                        onChange={(e) => setRid(e.target.value)}
                        placeholder="Введите RID"
                    />
                </FormGroup>
                <FormButtons>
                    <FilterButton type="button" onClick={downloadJSON}>Скачать JSON</FilterButton>
                    <DownloadButton type="button" onClick={downloadCSV}>Скачать CSV</DownloadButton>
                    <ResetButton type="button" onClick={handleResetFilters}>Сбросить</ResetButton>
                </FormButtons>
            </FilterForm>

            {/* Отображение сообщений об успехе и ошибках */}
            {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
            {error && <ErrorMessage>Ошибка: {error}</ErrorMessage>}

            {/* Отображение состояния загрузки */}
            {loading && <Loading>Загрузка...</Loading>}

            {/* Отображение таблицы с данными */}
            {!loading && !error && (
                filteredData.length > 0 ? (
                    <>
                        {/* Добавляем обертку для таблицы */}
                        <TableResponsive>
                            <DataTable>
                                <thead>
                                <tr>
                                    <TableHeader>Date</TableHeader>
                                    <TableHeader>PID</TableHeader>
                                    <TableHeader>RID</TableHeader>
                                    <TableHeader>Conference</TableHeader>
                                    <TableHeader>Full Name</TableHeader>
                                    <TableHeader>Email</TableHeader>
                                    <TableHeader>Phone</TableHeader>
                                    <TableHeader>Job Title</TableHeader>
                                    <TableHeader>Company</TableHeader>
                                    <TableHeader>How did you hear about us?</TableHeader>
                                </tr>
                                </thead>
                                <tbody>
                                {currentRecords.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableData>{new Date(row['Date']).toLocaleString()}</TableData>
                                        <TableData>{row['PID']}</TableData>
                                        <TableData>{row['RID']}</TableData>
                                        <TableData title={row['Conference']}>
                                            {row['Conference'].length > 30 ? row['Conference'].substring(0, 30) + '...' : row['Conference']}
                                        </TableData>
                                        <TableData>{row['Full Name']}</TableData>
                                        <TableData>{row['Email']}</TableData>
                                        <TableData>{row['Phone']}</TableData>
                                        <TableData>{row['Job Title']}</TableData>
                                        <TableData>{row['Company']}</TableData>
                                        <TableData title={row['How did you hear about us?']}>
                                            {row['How did you hear about us?'].length > 30 ? row['How did you hear about us?'].substring(0, 30) + '...' : row['How did you hear about us?']}
                                        </TableData>
                                    </TableRow>
                                ))}
                                </tbody>
                            </DataTable>
                        </TableResponsive>

                        {/* Пагинация */}
                        <Pagination>
                            <PageButton
                                onClick={() => paginate(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Предыдущая
                            </PageButton>
                            {Array.from({ length: totalPages }, (_, i) => i + 1).map(number => (
                                <PageButton
                                    key={number}
                                    onClick={() => paginate(number)}
                                    className={number === currentPage ? 'active' : ''}
                                >
                                    {number}
                                </PageButton>
                            ))}
                            <PageButton
                                onClick={() => paginate(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                Следующая
                            </PageButton>
                        </Pagination>
                    </>
                ) : (
                    <NoData>Данные не найдены.</NoData>
                )
            )}
        </Container>
    );
};

export default Data;