import React, { useEffect, useState, useMemo } from 'react';
import api from "../../api/endpoints/events";
import { useParams } from "react-router-dom";
import logo from '../../assets/images/logo.png';


function Header() {
    const { id } = useParams();
    // eslint-disable-next-line no-unused-vars
    const [conference, setConference] = useState({});
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const fetchConferenceData = async () => {
            try {
                const { data } = await api.get(`/Event/get-event-by-slug/${id}`);
                setConference(data);
            } catch (error) {
                console.error('Error fetching conference data:', error);
            }
        };

        if (id) {
            fetchConferenceData();
        }
    }, [id]);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const logoSrc = useMemo(() => logo, []);


    const liStyle =
        useMemo(() => ({ color: '#fff', marginRight: '10px' }), []);


    const logoStyle = useMemo(() => ({
        height: '50px',
        width: 'auto',
        marginRight: '10px',
    }), []);


    const headerStyle = useMemo(() => ({
        paddingTop: '25px',
        backgroundColor: isScrolled ? '#313F50' : 'initial', // Начальный цвет 'initial', синий цвет при прокрутке
        paddingBottom: '10px',
    }), [isScrolled]);


    return (
        <header className={`header ${isScrolled ? 'scrolled' : ''}`} style={headerStyle}>
            <div className="desktop-header" style={{ paddingTop: '10px', marginTop: '10px', height: '40px' }}>
                <a href='/'>
                    <img
                        src={logoSrc}
                        alt="Logo"
                        style={logoStyle}
                    />
                </a>
                <nav>
                    <ul className="nav-list">
                        <li style={liStyle}><a href="/about" style={liStyle}>ABOUT US</a></li>
                        <li style={liStyle}><a href='/sponsorship' style={liStyle}>SPONSORSHIP</a></li>
                        <li style={liStyle}><a href='/conferences' style={liStyle}>B2B CONFERENCES</a></li>
                        <li style={liStyle}><a href="/faq" style={liStyle}>FAQs</a></li>
                        <li style={liStyle}><a href="/gallery" style={liStyle}>GALLERY</a></li>
                        <li style={liStyle}><a href='/events' style={liStyle}>PAST EVENTS</a></li>
                        <li style={liStyle}><a href='/contacts' style={liStyle}>CONTACT US</a></li>
                    </ul>
                </nav>
            </div>
        </header>
    );
}

export default Header;
